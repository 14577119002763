import * as React from "react"
import { Link } from "gatsby"


export default class Login extends React.Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <>
        <h1> Login Page </h1>
      </>
      )
  }
}
